<template>
    <v-dialog v-model="renameDialog" width="500">
        <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on">
                <slot></slot>
            </div>
        </template>
        <v-card>
            <v-card-title>Rename {{ current.long_id }}</v-card-title>
            <v-card-text class="pt-3">
                <v-text-field autofocus label="New name for application" v-model="newName" :disabled="loading" outlined></v-text-field>
                <v-textarea label="Description" v-model="newDescription" :disabled="loading" outlined></v-textarea>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="secondary" text @click="renameDialog = false" :disabled="loading">Cancel</v-btn>
                <v-btn color="primary" text @click="updateAppName" :loading="loading">Rename</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: 'AppRenameDialog',
    data() {
        return {
            loading: false,
            newName: '',
            newDescription: '',
            renameDialog: false
        }
    },
    props: {
        current: { type: Object, required: true }
    },
    methods: {
        nameChanged() {
            return this.newName !== this.current.long_id
        },
        descriptionChanged() {
            return this.newDescription !== this.current.description
        },
        bothUnchanged() {
            return !(this.nameChanged() || this.descriptionChanged())
        },
        async updateAppName() {
            if (this.bothUnchanged()) {
                this.renameDialog = false
                return
            }
            this.loading = true

            const contents = {}
            if (this.nameChanged()) contents.long_id = this.newName
            if (this.descriptionChanged()) contents.description = this.newDescription
            try {
                await this.$axios.patch(`/applications/${this.current.aid}`, contents)
            } catch (error) {
                this.$store.dispatch('showSnackBar', {
                    snackBarText: 'Failed to rename application',
                    snackBarTimeout: 5000,
                    snackBarIcon: 'error'
                })
            }
            this.$store.dispatch('showSnackBar', {
                snackBarText: 'Application successfully changed.',
                snackBarTimeout: 5000,
                snackBarIcon: 'check_circle'
            })
            await this.$store.dispatch('snapshotStore/fetchApplications', this.current.snid)
            this.loading = false
            this.renameDialog = false
        }
    },
    mounted() {
        this.newDescription = this.current.description
        this.newName = this.current.long_id
    }
}
</script>
